.heading-container {
    position: relative;
    height: 100vh;
    max-width: 100%;

    @media only screen and (max-width: $desktop) {
      height: 50vh;
    }
}

.h-slide {
width: 100%;
height: 100%;
opacity: 0;
position: absolute;
inset: 0;
transition: opacity 1s ease-in-out;

    &.active {
        opacity: 1;
        animation: slide-scale 0.5s;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__name {
        position: absolute;
        padding-right: 2rem;

        @media only screen and (max-width: $desktop) {
          left: 5%;
          bottom: 5%;
        }

        @media only screen and (min-width: $desktop) {
          left: 7%;
          bottom: 15%;
        }
        
        h2 {
          color: $white;

          @media only screen and (max-width: $desktop) {
            font-size: 1.5rem;
          }

          @media only screen and (min-width: $desktop) {
            font-size: 3rem;
          }
        }
    }
}

.billboard {
    display: block;
    height: 100%;
    margin-bottom: 2.5rem;
}

.teaser {
    position: relative;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media only screen and (max-width: $desktop-lg) {
      display: block;
      padding: 6.25rem 0.3rem 5.5rem;
    }

    @media only screen and (min-width: $desktop-lg) {
      display: flex;
      padding: 8.75rem 0 6.77rem;
    }

    h5,p {
      margin: 0;
      text-transform: uppercase;
    }
}

.teaser-left, .teaser-right {
    max-width: 50%;
    display: flex;
    align-items: center;
    position: relative;

    @media only screen  and (max-width: $desktop-lg) {
      max-width: 100%;
    }
}

.teaser-left {
    justify-content: right;

    @media only screen and (max-width: $desktop-lg) {
      margin-bottom: 4rem;
      
      img {
        float: right;
      }
    }
}

.teaser-right {
    justify-content: left;

    @media only screen and (max-width: $desktop-lg) {
      margin-top: 4rem;

      img {
        float: left;
      }
    }
}

.teaser-img {
    max-width: 60%;
}

.teaser-info {
  z-index: 1;

  @media only screen  and (max-width: $desktop-lg) {
    width: 100%;
    position: relative;
  }

  @media only screen  and (min-width: $desktop-lg) {
    width: 100%;
    position: unset;
  }
}

.l-mushroom {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen  and (max-width: $tablet-md) {
    top: -7rem;
    right: -1.5rem;
  }

  @media screen and (min-width: $tablet-md) and (max-width: $desktop-lg) {
    top: -10.5rem;
    right: -1rem;
  }

  @media only screen  and (min-width: $desktop-lg) {
    top: -2.5rem;
    right: 14rem;
  }

  &__dsc {
    text-align: center;

    @media only screen  and (max-width: $tablet-md) {
      h5 {
        font-size: 0.8rem;
      }
      p {
        font-size: 0.6rem;
      }
    }

    @media only screen  and (min-width: $tablet-md) {
      h5 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
  }

  svg {
    height: 3rem;
    padding-left: 0.5rem;

    @media only screen  and (max-width: $tablet-md) {
      height: 2.5rem;
    }
  }
}

.l-calendar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen  and (max-width: $tablet-md) {
    top: -2.5rem;
    right: -7%;
  }

  @media screen and (min-width: $tablet-md) and (max-width: $desktop-lg) {
    top: -3.5rem;
    right: 5%;
  }

  @media only screen  and (min-width: $desktop-lg) {
    top: 3.5rem;
    right: 21rem;
  }

  &__dsc {
    text-align: center;

    @media only screen  and (max-width: $tablet-md) {
      h5 {
        font-size: 0.8rem;
      }
      p {
        font-size: 0.6rem;
      }
    }

    @media only screen  and (min-width: $tablet-md) {
      h5 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
  }

  svg {
    height: 3rem;
    padding-left: 0.5rem;

    @media only screen  and (max-width: $tablet-md) {
      height: 2.5rem;
    }
  }
}

.l-jar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen  and (max-width: $tablet-md) {
    top: 1.5rem;
    right: -1rem;
  }

  @media screen and (min-width: $tablet-md) and (max-width: $desktop-lg) {
    top: 3.5rem;
    right: 1rem;
  }

  @media only screen  and (min-width: $desktop-lg) {
    top: 10rem;
    right: 20.5rem;
  }

  &__dsc {
    text-align: center;

    @media only screen  and (max-width: $tablet-md) {
      h5 {
        font-size: 0.8rem;
      }
      p {
        font-size: 0.6rem;
      }
    }

    @media only screen  and (min-width: $tablet-md) {
      h5 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
  }

  svg {
    height: 3rem;
    padding-left: 0.5rem;

    @media only screen  and (max-width: $tablet-md) {
      height: 2.5rem;
    }
  }
}

.r-mushroom {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen  and (max-width: $tablet-md) {
    top: -8rem;
    left: -6.5rem;
  }

  @media screen and (min-width: $tablet-md) and (max-width: $desktop-lg) {
    top: -12rem;
    left: -2rem;
  }

  @media only screen  and (min-width: $desktop-lg) {
    top: -3.5rem;
    left: 9.5rem;
  }

  &__dsc {
    text-align: center;

    @media only screen  and (max-width: $tablet-md) {
      h5 {
        font-size: 0.8rem;
      }
      p {
        font-size: 0.6rem;
      }
    }

    @media only screen  and (min-width: $tablet-md) {
      h5 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
  }

  svg {
    height: 3rem;
    padding-right: 0.5rem;

    @media only screen  and (max-width: $tablet-md) {
      height: 2.5rem;
    }
  }
}

.r-calendar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen  and (max-width: $tablet-md) {
    top: -2.5rem;
    left: -2%;
  }

  @media screen and (min-width: $tablet-md) and (max-width: $desktop-lg) {
    top: -3.5rem;
    left: 5%;
  }

  @media only screen  and (min-width: $desktop-lg) {
    top: 4rem;
    left: 19rem;
  }

  &__dsc {
    text-align: center;

    @media only screen  and (max-width: $tablet-md) {
      h5 {
        font-size: 0.8rem;
      }
      p {
        font-size: 0.6rem;
      }
    }

    @media only screen  and (min-width: $tablet-md) {
      h5 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
  }

  svg {
    height: 3rem;
    padding-right: 0.5rem;

    @media only screen  and (max-width: $tablet-md) {
      height: 2.5rem;
    }
  }
}

.r-jar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media only screen  and (max-width: $tablet-md) {
    top: 3rem;
    left: -6%;
  }

  @media screen and (min-width: $tablet-md) and (max-width: $desktop-lg) {
    top: 4rem;
    left: 5%;
  }

  @media only screen  and (min-width: $desktop-lg) {
    top: 10rem;
    left: 24rem;
  }

  &__dsc {
    text-align: center;

    @media only screen  and (max-width: $tablet-md) {
      h5 {
        font-size: 0.8rem;
      }
      p {
        font-size: 0.6rem;
      }
    }

    @media only screen  and (min-width: $tablet-md) {
      h5 {
        font-size: 1rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
  }

  svg {
    height: 3rem;
    padding-right: 0.5rem;

    @media only screen  and (max-width: $tablet-md) {
      height: 2.5rem;
    }
  }
}

.ico-1 {
  animation-delay: 0.5s;
}

.ico-2 {
  animation-delay: 0.8s;
}

.ico-3 {
  animation-delay: 1.1s;
}

.teaser-dsc-1 {
  animation-delay: 1.5s;
}

.teaser-dsc-2 {
  animation-delay: 1.8s;
}

.teaser-dsc-3 {
  animation-delay: 2.1s;
}

.benefit-icons {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 140px 50px;
}

.benefit-icons-m {
  text-align: center;
  padding: 3rem 0.5rem;
}

// card
.h-cards {
  position: relative;

  @media only screen and (max-width: $desktop) {
    min-height: 1450px;
  }
  
  @media only screen and (min-width: $desktop) {
    height: 38.888rem;
    min-height: 100vh;
  }
}

.h-card {
    position: absolute;
    inset: 0;
    display: grid;
    border: none;
    background: $light-backgorund;
    grid-template-columns: repeat(12, 1fr);
    opacity: 0;
    transition: opacity 0.5s;
    margin-bottom: 0;

    &.active {
      opacity: 1;
    }
  }
  
  .h-card-img {
    grid-column: span 6;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @media only screen and (max-width: $desktop) {
      min-height: 50vh;
      grid-column: span 12;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .h-card__description {
    grid-column: span 6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid black;
    padding: 5%;
    margin: 8%;

    @media only screen and (max-width: $desktop) {
      grid-column: span 12;
    }

    h1 {
      text-transform: uppercase;
      font-size: 2rem;
    }

    h2 {
      color: $gold-color;
      font-family: 'blacklist', sans-serif;
    }

    &-text {
      font-family: Verdana, Arial, Helvetica, sans-serif;
      text-align: justify;
      padding-top: 1rem;
      padding-bottom: 1rem;

      p {
        font-family: Verdana, Arial, Helvetica, sans-serif;
        text-align: justify;
      }
    }
  }

  //cars-about
  .h-abouts {
    position: relative;
  
    @media only screen and (max-width: $desktop) {
      height: 1350px;
    }
    
    @media only screen and (min-width: $desktop) {
      height: 38.888rem;
      min-height: 100vh;
    }
  }
  
  .h-about {
      position: absolute;
      inset: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      background: $light-backgorund;
      border: none;
      opacity: 0;
      transition: opacity 0.5s;
      margin-bottom: 0;

      @media only screen and (max-width: $desktop) {
        grid-template-areas: 
        "figure"
        "caption";
      }

      @media only screen and (min-width: $desktop) {
        grid-template-areas: 
        "caption figure";
      }
  
      &.active {
        opacity: 1;
      }
    }
    
    .h-about-img {
      grid-area: figure;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
  
      @media only screen and (max-width: $desktop) {
        grid-column: span 12;
        min-height: 50vh;
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    .h-about__description {
      grid-area: caption;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px solid black;
      padding: 5%;
      margin: 8%;
  
      @media only screen and (max-width: $desktop) {
        grid-column: span 12;
      }
  
      h1 {
        text-transform: uppercase;
        font-size: 2rem;
      }
  
      h2 {
        color: $gold-color;
        font-family: 'blacklist', sans-serif;
      }
  
      &-text {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-family: Verdana, Arial, Helvetica, sans-serif;
        text-align: justify;

        p {
          font-family: Verdana, Arial, Helvetica, sans-serif;
          text-align: justify;
        }
      }
    }

  //map
  .map-desktop {
    position: relative;

    @media only screen and (max-width: $desktop) {
      display: none;
    }

    @media only screen and (min-width: $desktop) {
      display: block;
    }
  }

  .map-img {
    position: relative;
    width: 100%;
    height: auto;
  }

  .map-pins {
    position: absolute;
    width: 100%;
    height: auto;
    inset: 0;
  }

  .mob-pins {
    position: absolute;
    width: 100%;
    height: auto;
    inset: 0;
  }

  .map-mob {
    position: relative;

    @media only screen and (max-width: $desktop) {
      display: block;
    }

    @media only screen and (min-width: $desktop) {
      display: none;
    }
  }

  // products slider - multi carousel 
  .products-slider {
    background: $light-backgorund;

    h2 {
      position: relative;
      font-family: 'blacklist', sans-serif;
      z-index: 50;

      @media only screen and (max-width: $desktop) {
        font-size: 2rem;
      }

      @media only screen and (min-width: $desktop) {
        font-size: 3rem;
      }
    }

    .home-products-swiper {
      position: relative;
      justify-self: center;
      align-self: center;
      height: 100%;
      overflow: hidden;
      max-height: 100%;
      padding-top: 4rem;
      padding-bottom: 4rem;

      .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        content: none;
      }
      .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
        content: none;
      }

      .swiper-button-prev {
        position: absolute;

        @media only screen and (min-width: $desktop) {
          left: 0;
          width: 3rem;
          height: 1.6rem;
        }

        svg {
            width: 100%;
            height: 1.6rem;
            transform: rotate(180deg);
        }
      }

      .swiper-button-next {
        position: absolute;

        @media only screen and (min-width: $desktop) {
          right: 0;
          width: 3rem; 
          height: 1.6rem;
        }

        svg {
            width: 100%;
            height: 1.6rem;
            stroke-width: 3;
        }
      }
    }

  }

// home slider animation
@keyframes slide-opacity {
    from {
      transform: opacity(0);
    }
    to {
      transform: opacity(1);
    }
  }
  
@keyframes slide-scale {
    from {
      transform: scale(0.6);
    }
    to {
      transform: scale(1);
    }
  }