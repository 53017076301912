.successfull-order {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $dark-background;
    color: #fff;

    section {
        text-align: center;
        text-transform: uppercase;

        a {
            color: $gold-color;
            transition: color 0.5s;

                &:hover {
                    color: adjust-color($gold-color, $lightness: 20%);
                }
        }
    }
}