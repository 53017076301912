// @font-face {
//     font-family: 'Bruno Ace';
//     font-style: normal;
//     src: url('../fonts/BrunoAceSC-Regular.ttf') format('woff2');
// };

// html body {
//     font-family: Verdana, Arial, Helvetica, sans-serif;
// }

@font-face {
    font-family: 'merdeka';
    src: url('../fonts/MerdekaIndonesia.ttf') format('truetype'), 
        url('../fonts/MerdekaIndonesia.woff2') format('woff2'),
        url('../fonts/MerdekaIndonesia.otf') format('opentype');
  }

@font-face {
font-family: 'blacklist';
src: 
    url('../fonts/Blacklist.otf') format('opentype'),
    url('../fonts/Blacklist.ttf') format('truetype');
}

@font-face {
    font-family: 'trajan';
    src: 
        url('../fonts/TrajanProRegular.ttf') format('truetype');
}

