.truffles {
    background: #000;
}

.t-container {
    display: grid;
    width: 100%;
    padding: 5rem 0;

    @media only screen  and (max-width: $desktop) {
        grid-template-columns: 1fr;
        padding-top: 2rem;
    }

    @media only screen  and (min-width: $desktop) {
        grid-template-columns: repeat(2, 1fr);
    }

    .t-truffle {
        width: 90%;
        justify-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen  and (max-width: $desktop) {
            padding-top: 1rem;
        }

        figure {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            width: 70%;

            @media only screen  and (min-width: $desktop) {
                width: 50%;
            }
        }

        .t-caption {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 1rem;
            text-align: justify;
        }

        h1 {
            color: $gold-color;
            text-align: center;
            font-family: 'merdeka', sans-serif;
        }

        .t-icons {
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem 1rem;
            margin: 1rem auto;
            border-top: 1px solid $gold-color;
            border-bottom: 1px solid $gold-color;
            margin-bottom: 2.2rem;

            .t-icon {
                padding: 0 1rem;
            }

            svg {
                width: 2.5rem;
            }
        }

        .t-description {
            width: 90%;
            color: $gold-color;
            padding: 0 1rem;

            p {
                font-family: Verdana, Tahoma, sans-serif;
            }
        }
    }
}

.truffles-w {
    background: #fff;
    padding: 2rem 0;

    .t-baner {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        padding-bottom: 3rem;

        @media only screen  and (max-width: $desktop) {
            flex-direction: column;
        }

        &-title {
            text-align: center;
            text-transform: uppercase;
            padding: 2.5rem 1rem;
            background: #fff;
            z-index: 1;

            @media only screen  and (max-width: $desktop) {
                width: 100%;
            }

            h2 {
                margin: 0;
                font-weight: 400;
                font-size: 1.5rem;
            }
        }

        &__icons-l{
            display: flex;
            transition: transform 2s;
            @media only screen  and (max-width: $desktop) {
                transform: translateY(100%);
            }
        
            @media only screen  and (min-width: $desktop) {
                transform: translateX(70%);
            }

            &.show{
                @media only screen  and (max-width: $desktop) {
                    transform: translateY(0%);
                }
            
                @media only screen  and (min-width: $desktop) {
                    transform: translateX(0%);
                }
            }
        }

        &__icons-r{
            display: flex;
            transition: transform 2s;

            @media only screen  and (max-width: $desktop) {
                transform: translateY(-100%);
            }
        
            @media only screen  and (min-width: $desktop) {
                transform: translateX(-70%);
            }

            &.show{
                @media only screen  and (max-width: $desktop) {
                    transform: translateY(0%);
                }
            
                @media only screen  and (min-width: $desktop) {
                    transform: translateX(0%);
                }
            }
        }
    }
}

.t-icon {
    padding: 0 2rem;
    text-align: center;

    svg {
        width: 3rem;
    }

    p {
        color: $gold-color;
        margin: 0;
    }
}

.t-tips {
    padding-bottom: 3rem;
    &-title {
        text-align: center;
        padding-bottom: 1rem;

        h2 {
            text-transform: uppercase;
            font-weight: 400;
            margin: 0;
        }

        h3 {
            font-family: 'merdeka', sans-serif;
            font-size: 2rem;
            color: $gold-color;
        }
    }

    &-tip {
        display: flex;

        p {
            font-family: Verdana, Tahoma, sans-serif;
        }

        .num-circle {
            width: 1.5rem;
            height: 1.5rem;
            background-color: $gold-color;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            padding: 0.5rem;
            margin-right: 0.5rem;
            font-weight: 600;
          }
    }
}

.t-coworkers {

    &__imgs {
        display: grid;
        grid-template-columns: repeat(12, 1fr);

        &-img {
            @media only screen  and (max-width: $desktop) {
                grid-column: span 6;
            }
        
            @media only screen  and (min-width: $desktop) {
                grid-column: span 3;
            }

            img {
                width: 100%;
            }
        }
    }
    &-dsc {
        padding-top: 2rem;
        padding-bottom: 2rem;

        h2 {
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
            padding-bottom: 3rem;
        }

        p {
            font-family: Verdana, Tahoma, sans-serif;
        }
    }
}
