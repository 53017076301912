.pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        text-decoration: none;
    }

    .link-active {
        color: $gold-color;
    }
}