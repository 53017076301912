.r-detail {
    background: #000;
    color: #fff;

    &.w-bg {
        background: $light-backgorund;
        color: #000;
    }
}
.r-heading {
    position: relative;
    max-width: 100%;

    @media only screen and (max-width: $desktop) {
        height: 50vh;

        &__img {
            display: none;
        }

        &__img-mob {
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            transition: opacity 1s ease-in-out;
            
            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    @media only screen and (min-width: $desktop) {
        height: 75vh;

        &__img-mob {
            display: none;
        }

        &__img {
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
            transition: opacity 1s ease-in-out;
            
            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }


}

.r-title__container {
    padding: 2rem 0 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    justify-content: center;

    .r-title{
        position: relative;
        border-bottom: 1px solid #fff;

        @media only screen and (min-width: $desktop) {
            max-width: 50%;
        }

        h1 {
            font-weight: 300;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 5px;
            color: #fff;
        }

        &.w-bg {
            color: #000;

            h1 {
                border-bottom: 1px solid #000;
            }
        }
    }
}

.r-overviews {
    display: flex;
    justify-content: center;

    &__overview {
        display: block;
        text-align: center;
        padding: 0 1rem;

        &:first-child, &:last-child {
            @media only screen and (max-width: $desktop) {
                width: 6rem;
            }
            @media only screen and (min-width: $desktop) {
                width: 8rem;
            }
        }

        &-ico {
            width: 2.5rem;
            height: 2.5rem;
            margin-left: auto;
            margin-right: auto;
        }

        &-detail {
            text-transform: uppercase;
            text-align: center;
            padding-top: 0.5rem;

            @media only screen and (max-width: $desktop) {
                font-size: 0.5rem;
            }
            @media only screen and (min-width: $desktop) {
                font-size: 0.8rem;
            }
        }
    }
}

.r-overviews__overview-ico {
    &.w-bg {
        svg path{
            fill: #000;
        }
    }
}

.r-ingredients {
    padding: 1rem 0 1rem 2rem; 

    &-item {
        position: relative;

        &.text-gold {
            color: $gold-color;
        }

        p {
            margin-bottom: 0.5rem;
            font-family: Verdana, Tahoma, sans-serif;
        }

        &.w-bg ::before {
            background: #000;
        }

        &.text-gold ::before{
            background: $gold-color;
        }

        ::before {
            position: absolute;
            left: -1rem;
            top: 0.5rem;
            content: '';
            background: #fff;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
}

.r-recipe {
    padding-bottom: 2rem;

    @media only screen and (min-width: $desktop) {
        width: 80%;
    }

    p {
        font-family: Verdana, Tahoma, sans-serif;
    }

}

.r-products {
    position: relative;
    background: #fff;
    color: #000;

    @media only screen and (max-width: $desktop) {
        padding-top: 2rem;
        padding-bottom: 6.25rem;
    }

    @media only screen and (min-width: $desktop) {
        padding-top: 3rem;
        padding-bottom: 7.777rem;
    }
    

    &-title {
        display: flex;
        padding: 1.5rem 0;
        text-transform: uppercase;

        h3 {
            min-width: fit-content;
            margin: 0;
        }
    }

    .r-line {
        position: relative;
        width: 100%;
        margin-left: 1rem;

        ::after {
            position: absolute;
            right: 0;
            top: 50%;
            content: '';
            background: #000;
            width: 100%;
            height: 2px;
            transform: translateY(-50%);
        }
    }
}

.r-product {
    display: flex;
    width: 100%;
    border: 1px solid #000;
    margin: 1rem 0;

    &-img {
        margin-top: auto;
        margin-bottom: auto;

        img {

            @media only screen and (max-width: $desktop) {
                width: 10rem;
            }

            @media only screen and (max-width: $desktop) {
                width: 13rem;
            }
        }
    }

    &__details {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: auto 0;
        text-transform: uppercase;

        &-title {
            max-width: 50%;
            align-self: center;
            text-align: center;

            h3 {
                @media only screen and (max-width: $desktop) {
                    font-size: 0.8rem;
                    text-align: center;
                }
            }
        
        }

        &-price {
            max-width: 50%;
            align-self: center;

            @media only screen and (max-width: $desktop) {
                font-size: 0.8rem;
            }
        }

        &-link {
            max-width: 50%;
            align-self: center;

            @media only screen and (max-width: $desktop) {
                max-width: 100%;
                font-size: 0.8rem;
            }
            a {
                color: $gold-color;
                transition: color 0.5s;

                &:hover {
                    color: adjust-color($gold-color, $lightness: 20%);
                }
            }
        }
    }
}