.contact {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;

    &-bgd {
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        z-index: -1;
        filter: blur(10px);

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &-title {
        padding-top: 2rem;
        margin: 0;
        text-align: center;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
    }

    .contact-container {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        column-gap: 2rem;
        padding: 2rem 0 3rem;
        background: rgba(0, 0, 0, 0.5);

        .contact-box {
            align-self: center;
            @media only screen and (max-width: $desktop)  {
                grid-column: span 12;
                justify-self: center;
            }
            @media only screen and (min-width: $desktop)  {
                grid-column: span 6;
                justify-self: right;
            }
        }

        .contact-option {
            align-self: center;
            @media only screen and (max-width: $desktop)  {
                grid-column: span 12;
                padding: 3rem 0;
            }
            @media only screen and (min-width: $desktop)  {
                grid-column: span 6;
                justify-self: left;
            }

            p {
                color: #fff;
                text-align: center;
                font-size: 1.5rem;
            }

            a {
                color: #fff;
                text-decoration: none;

                &:hover {
                    color: $gold-color;
                }

                &:hover svg {
                    fill: $gold-color;
                }
            }

            span a {
                color: $gold-color;

                &:hover {
                    color: adjust-color($gold-color, $lightness: 20%);
                }
            }
        }
    }

    .contact-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .contact-msg {
            color: #fff;
            text-align: center;
        }
    }

    .contact-form {
        @media only screen and (max-width: $desktop)  {
            width: 90%;
        }

        label {
            display: none;
        }

        .contact-form-name:focus, .contact-form-email:focus, .contact-form-textarea:focus {
            box-shadow: 0 0 15px $gold-color;
            border: 1px solid $gold-color;
            outline: $gold-color;
        }

        button {
            padding: 1rem;
        }
    }

    .contact-form form button {
        width: 100%;
    }
    
    .contact-form-name, .contact-form-email, .contact-form-textarea {
        width: 100%;
        padding: 1rem;
    }
}