//colors
$light-backgorund: #faf9f4;
$dark-background: #0d0d0d;
$black: #010101;
$white: #ffffff;
$gold-color: #b9a171;
$primary-color: #99ffdd;

//break points
$tablet-md: 700px;
$desktop: 992px;
$desktop-lg: 1200px;