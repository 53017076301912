.unsubscribe {
    min-height: 90vh;
    background: #000;
    color: #fff;
}

.unsubscribe-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 90vh;

    h3 {
        margin-bottom: 4rem;
    }

    a {
        color: $gold-color;
        transition: color 0.5s;

        &:hover {
            color: adjust-color($gold-color, $lightness: 20%);
        }
    }
}

.unsubscribe-form {
    display: flex;
    justify-content: center;
    align-items: center;

    form {
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            padding: 0.8rem;
        }
    }

    label {
        display: none;
    }

    input {
        outline: 2px solid $gold-color;
        margin: 1rem;
        padding: 0.5rem;
    }
}