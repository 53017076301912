.product-detail {
    background: $light-backgorund;
}

.product {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-top: 5.5rem;
    padding-bottom: 2rem;

    .products-swiper {
      position: relative;
      justify-self: center;
      align-self: center;
      height: 100%;
      overflow: hidden;
      max-height: 100%;

      @media only screen and (max-width: $desktop) {
          grid-column: span 12;
          height: 30vh;
          padding-bottom: 1rem;
      }

      @media only screen and (min-width: $desktop) {
          grid-column: span 6;
      }

      .swiper-wrapper {
        position: relative;
        height: 100%;
  
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
  

      .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
        content: none;
      }
      .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
        content: none;
      }

      .swiper-button-prev {
        position: absolute;

        @media only screen and (min-width: $desktop) {
          width: 5rem; 
          height: 1.6rem;
        }

        svg {
            width: 5rem; 
            height: 1.6rem;
            transform: rotate(180deg);
        }
      }

      .swiper-button-next {
        position: absolute;

        @media only screen and (min-width: $desktop) {
          width: 5rem; 
          height: 1.6rem;
        }

        svg {
            width: 5rem; 
            height: 1.6rem;
        }
      }
    }

    &__description {
        @media only screen and (max-width: $desktop) {
            grid-column: span 12;
        }

        @media only screen and (min-width: $desktop) {
            grid-column: span 6;
            margin: 0 2rem;
        }

        h1 {
          text-align: center;
          text-transform: lowercase;
          font-weight: 600;
          padding-bottom: 0.5rem;
          border-bottom: 2px solid #000;
          width: fit-content;
        }

        h3 {
          text-align: center;
          color: $gold-color;
          font-family: 'merdeka', monospace;
        }
    }
}

.product__description-benefit-icons {
  overflow: hidden;
  width: 100%;
}

.benefits-swiper {
   
  .swiper-slide {
    background: inherit;

    .card-img {
      text-align: center;
      white-space: nowrap;
  
        p {
          text-transform: uppercase;
          @media (max-width: 767px) {
          margin: 0;
          font-size: 0.6rem;
          }
  
          @media (min-width: $desktop) and (max-width: $desktop-lg) {
            font-size: 0.8rem;
          }
        }
    }
  
    .card-img svg {
      width: 100%;
      aspect-ratio: 1/1;
      min-width: 40px; 
      max-width: 100px;
      height: auto;
    } 
  }

}


.product-dsc {
  padding: 4rem 0;
  background: #000;

  h2 {
    margin-bottom: 1.5rem;
  }

  p {
    font-family: Verdana, Tahoma, sans-serif;
    color: $gold-color;
  }

  ul li {
    font-family: Verdana, Tahoma, sans-serif;
    color: $gold-color;

    &::marker {
      color: $gold-color;
    }
  }

}

.product-video {
  width: 100%;
  margin: 0;
  padding: 0;

  &__player {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.product_item {
  text-align: center;
  padding: 2.5rem 0;
}

.product_item-form form button {
  padding: 1em 0.8em;
  box-shadow: none;
}

.product_item-form {
    display: inline-block;
    border-top: 1px solid #000;
    margin-bottom: 0.5rem;
    margin-right: 1rem;

    .units-details {
      background: #000;
      color: $gold-color;
      padding: 0.8rem 1rem;
      text-align: center;
      margin-top: auto;
      margin-bottom: auto;

      p {
        margin-bottom: 0;
      }
    }

    form {
      border: 1px solid $gold-color;
      margin-bottom: 0.5rem;
    }

    form select {
      background-color: $light-backgorund;
      color: $gold-color;
      width: 3rem;
      border: none;
      font-size: 1rem;
      -webkit-appearance: button;
      appearance: button;
      outline: none;
      cursor: pointer;
    }

    .form select:focus {
      outline: none;
    }

    form label {
      color: $gold-color;
      padding: 1rem;
      text-transform: uppercase;
    }

    .btn-gold {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      border: none;
    }
}

.product-recensions {
  background: #fff;

  &__container {
    padding-bottom: 1.5rem;
    h4 {
        text-transform: uppercase;
        font-size: 1.2rem;
    }
  }

  .p-recensions-title {
    display: flex;
    padding: 1.5rem 0;
    text-transform: uppercase;

    h3 {
        min-width: fit-content;
        margin: 0;
    }
  }

  .r-line {
    position: relative;
    width: 100%;
    margin-left: 1rem;

    ::after {
        position: absolute;
        right: 0;
        top: 50%;
        content: '';
        background: #000;
        width: 100%;
        height: 2px;
        transform: translateY(-50%);
    }
  }

  .recension {
    display: flex;
    border: 1px solid black;
    margin-bottom: 1rem;

    
  @media only screen and (max-width: $desktop) {
    flex-direction: column;
  }

  }


  .recension__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    @media only screen and (max-width: $desktop) {
      flex-direction: row;
    }

    @media only screen and (min-width: $desktop) {
      flex-direction: column;
    }

    &-ico {
        text-align: center;
        svg {
            width: 5rem;
        }
    }
    &-info {
        text-align: center;

        p {
            margin-bottom: 0.2rem;
        }

        p:nth-child(1) {
            white-space: nowrap;
        }

        p:nth-child(2) {
            color: $gold-color;
        }

        p:nth-child(3) {
            font-size: 0.7rem;
        }
    }
  }

  .recension__dsc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;

    @media only screen and (max-width: $desktop) {
      padding-top: 0;
    }

    &-rating {
        color: $gold-color;
        
        .recension-star {
            font-size: 2rem;
        }
    }

    &-body {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        p {
            margin: 0;
        }
    }
  }

  .r-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    background: $light-backgorund;

    h3 {
        text-transform: uppercase;
    }
  }

  .recension-form {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $desktop) {
      width: 100%;
    }

    @media only screen and (min-width: $desktop) {
      min-width: 300px;
      max-width: 50%;
    }


    &-name, &-email, &-textarea {
      margin: 0 0 0.5rem;
      border: 1px solid $gold-color;
      background: $light-backgorund;
      padding: 0.5rem;
    }

    button {
      padding: 0.5rem;
    }
    
    .stars {
        background: $light-backgorund;
        position: relative;

        .stars-container{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem 0;
          }
          
          .stars-container .container__items{
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            gap: 0 .5em;
            height: 100%;
            
            @media only screen and (max-width: $desktop) {
                width: 60%;
            }
        
            @media only screen and (min-width: $desktop) {
                min-width: 300px;
                max-width: 50%;
            }
          }
          
          .stars-container .container__items input{
            display: none;
          }
          
          .stars-container .container__items label{
            width: 20%;
            aspect-ratio: 1;
            cursor: pointer;
          }
          
          .stars-container .container__items label .star-stroke{
            display: grid;
            place-items: center;
            width: 100%;
            height: 100%;
            background: $gold-color;
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          }
          
          .stars-container .container__items label .star-stroke .star-fill{
            width: 70%;
            aspect-ratio: 1;
            background: $light-backgorund;
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          }
          
          .stars-container .container__items input:hover ~ label .star-stroke,.container .container__items input:checked ~ label .star-stroke{
            background: goldenrod;
          }
          
          .stars-container .container__items input:checked ~ label .star-stroke .star-fill{
            background: $gold-color;
          }
    }
  }

  .recension-form-name:focus, .recension-form-email:focus, .recension-form-textarea:focus {
    box-shadow: 0 0 10px $gold-color;
    border: 1px solid $gold-color;
    outline: $gold-color;
  }

}
