.corporate-gifts {
    padding-top: 4rem;

    .r-slide__name {
        text-align: center;
    }
    h1 {
        color: $gold-color;
    }

    h3 {
        color: #fff;
        font-family: 'merdeka', monospace;
    }

    .desktop {
        @media only screen and (max-width: $desktop) {
            display: none;
        }
        @media only screen and (min-width: $desktop) {
            display: block;
        }
    }

    .mobile {
        @media only screen and (max-width: $desktop) {
            display: block;
        }
        @media only screen and (min-width: $desktop) {
            display: none;
        }
    }
}

.gifts-description {
    padding-top: 3rem;
    padding-bottom: 6rem;

    p {
        font-family: Verdana, Tahoma, sans-serif;
    }
}

.gifts {
    .product__description {
        a {
            color: $gold-color;
            transition: color 0.5s;

            &:hover {
                color: adjust-color($gold-color, $lightness: 20%);
            }
        }
    }
    .product__description h1 {
        color: $gold-color;
        padding-bottom: 0.1rem;
        margin-left: auto;
        margin-right: auto;
        border-bottom: 3px solid #000;
    }

    .product {
        display: flex;
        border-top: 5px solid #000;

        @media only screen and (max-width: $desktop) {
            flex-direction: column;
        }
        
        @media only screen and (min-width: $desktop) {
            flex-direction: row;
        }

        &__slider-container img {
            scale: 1.2;
        }
    }

    @media only screen and (min-width: $desktop) {
        .product:nth-child(even) {
            flex-direction: row-reverse;
        }   
    }

    .product__slider-container,
    .product__description {
        flex: 1;
        padding: 10px;
    }
}

.gift-dsc {
    padding-top: 2rem;

    p {
        font-family: Verdana, Tahoma, sans-serif;
        color: #000;
    }

    ul {
        font-family: Verdana, Tahoma, sans-serif;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 1.5rem;
            margin-bottom: 5px;
            color: #000;
        }

        li::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) rotate(45deg);
            width: 15px;
            height: 15px;
            background-color: #000;
        }
    }
}