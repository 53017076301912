:root {
  font-family: 'trajan', Verdana, Helvetica, sans-serif;
  font-size: 16px;
  font-size: clamp(16px, 1.25vw, 20px);
  text-rendering: geometricPrecision;
  scrollbar-gutter: stable;
  text-underline-position: under;
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 0.66;
  }
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 0.8;
  }
}

body, p, h1, h2, h3 {
  font-family: 'trajan', Verdana, Helvetica, sans-serif;
}

a {
  color: #fff;
  text-decoration: none;
}

.no-scroll {
  overflow: hidden;
}

.overlay-link {
    position: absolute;
    inset: 0;
    z-index: 2;
}

.fade-in {
    opacity: 0;
    animation-name: fade-in;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .l-div {
    opacity: 0;
    transform: translateX(-25%);
    transition: transform 1s, opacity 1s;
    
    &.show {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .r-div {
    opacity: 0;
    transform: translateX(25%);
    transition: transform 1s, opacity 1s;
  
    &.show {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .a-div {
    scale: 0;
    opacity: 0;
  
    &.show {
      opacity: 1;
      animation: text-scale 1.5s forwards;
    }
  }

  .d-div {
    scale: 0;
    opacity: 0;
  
    &.show {
      opacity: 1;
      animation: text-scale 1s forwards;
    }
  }
  
  .o-div {
    opacity: 0;
    transition: opacity 0.8s;
  
    &.show {
      animation: fade-in 1s forwards;
    }
  }

  .m-div {
    scale: 0;
    opacity: 0;
    transition: all 2s;

    &.show {
      scale: 1;
      opacity: 1;
    }
  }

  .text-gold {
    color: $gold-color;
  }


button {
  --c:  #b9a171; /* the color*/
  
  box-shadow: 0 0 0 0.1em inset var(--c); 
  --_g: linear-gradient(var(--c) 0 0) no-repeat;
  background: 
    var(--_g) calc(var(--_p,0%) - 100%) 0%,
    var(--_g) calc(200% - var(--_p,0%)) 0%,
    var(--_g) calc(var(--_p,0%) - 100%) 100%,
    var(--_g) calc(200% - var(--_p,0%)) 100%;
  background-size: 50.5% calc(var(--_p,0%)/2 + .5%);
  outline-offset: 0.1em;
  transition: background-size 0.4s, background-position 0s 0.4s;
}
button:hover {
  --_p: 100%;
  color: #000;
  transition: background-position 0.4s, background-size 0s, color 0.4s;
}
button:active {
  box-shadow: 0 0 9e9q inset #0009; 
  background-color: var(--c);
  color: #fff;
}

button {
  font-size: 1rem;
  cursor: pointer;
  padding: 0.2em 0.8em;
  border: none;
  color: $gold-color;
  text-transform: uppercase;
}
  
  @keyframes text-scale {
    0% {
      opacity: 0;
      scale: 0.6;
    }
    100% {
      opacity: 1;
      scale: 1;
    }
  }

.desktop {
  @media only screen and (max-width: $desktop)  {
    display: none;
  }
  @media only screen and (min-width: $desktop)  {
    display: block;
  }
}

.mobile {
  @media only screen and (max-width: $desktop)  {
    display: block;
  }
  @media only screen and (min-width: $desktop)  {
    display: none;
  }
}


.cookie-banner {
  display: none;
  position: fixed;
  padding: 1rem;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: white;
  text-align: center;
  z-index: 100;
  border: 1px solid $gold-color;
  margin-bottom: 1rem;
}

.cookie-content {
  max-width: 600px;
  margin: 0 auto;
  font-size: 0.8rem;

  a {
    color: $gold-color;
  }
}

.policies {
  padding: 6rem 0;
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $dark-background;
  min-height: 90vh;

  h1 {
    color: #fff;
  }

  p {
    color: #fff;
  }

  a {
    color: $gold-color;
    transition: color 0.5s;

        &:hover {
            color: adjust-color($gold-color, $lightness: 20%);
        }
  }
}
