nav {
    position: fixed;
    z-index: 99;
    width: 100%;
    max-width: 100%;
    height: 3.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    transition: height 0.5s;
    font-family: 'trajan', sans-serif;

    &.scrolled {
        background: rgb(0, 0, 0, 0.5);
        height: 2.777rem;
    }

    @media only screen and (max-width: $desktop)  {
        display: flex;
        justify-content: space-between;
    }

    .nav-logo {
        height: 100%;
        fill: transparent;
        z-index: 99;

        &.scrolled {
            height: 2.777rem;
        }
    }

    .nav-logo-svg {
        height: 100%;
        fill: transparent;
        z-index: 99;
        transition: height 0.5s;


        &.scrolled {
            height: 2.777rem;
        }
    }

    .menu-btn {
        margin: 0 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        cursor: pointer;
        transition: all 0.5s ease-in-out;  
        z-index: 99;
    
        @media only screen and (min-width: $desktop) {
            display: none;
          }
    }

    .menu-btn__burger {
        width: 2.1875rem;
        height: 3px;
        background: $gold-color;
        border-radius: 3px;
        box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
        transition: all .5s ease-in-out;
    }
    
    .menu-btn__burger::before,
    .menu-btn__burger::after {
        content: '';
        position: absolute;
        width: 2.1875rem;
        height: 3px;
        background: $gold-color;
        border-radius: 3px;
        box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
        transition: all 0.5s ease-in-out;
    }
    
    .menu-btn__burger::before {
        transform: translateY(-10px);
    }
    
    .menu-btn__burger::after {
        transform: translateY(10px);
    }
    
    /* ANIMATION */
    .menu-btn.open .menu-btn__burger {
        transform: translateX(-50px);
        background: transparent;
        box-shadow: none;
    }
    
    .menu-btn.open .menu-btn__burger::before {
        transform: rotate(45deg) translate(35px, -35px);
    }
    
    .menu-btn.open .menu-btn__burger::after {
        transform: rotate(-45deg) translate(35px, 35px);
    }
}

.nav-links {
    list-style: none;
    margin-bottom: 0;
    padding: 1rem 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $desktop)  {
        position: fixed;
        display: grid;
        justify-content: center;
        align-items: center;
        inset: 0;
        height: 100vh;
        width: 100%;
        background: $black;
        padding: 6rem 0;
        transform: translateX(100%);
        transition: transform 0.5s;

        &.open {
            transform: translateX(0%);
            max-height: 100%;
            overflow: auto;
        }
    }

    &__link {
        position: relative;
        padding: 0 1rem;

        @media only screen and (max-width: $desktop)  {
            padding: 1rem 0;
            text-align: center;
            align-self: center;
            justify-self: center;
            opacity: 0;
            scale: 0;
            transition: opacity 2s, scale 2s;

            &.open {
                opacity: 1;
                scale: 1;
            }
        }

        .navbar-link {
            color: $gold-color;
            font-size: 1.2rem;
            text-decoration: none;
            text-transform: uppercase;
            position: relative;

            @media only screen and (max-width: $desktop)  {
                font-size: 1.5rem;
            }

            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                content: '';
                width: 0;
                height: 3px;
                background: $gold-color;
                transition: all 0.5s;
            }

            &:hover::after{
                width: 100%;
            }

            &.active::after {
                width: 100%;
            }

            &--subnav {
                font-size: 0.9rem;
                white-space: nowrap;

                @media only screen and (max-width: $desktop)  {
                    font-size: 1rem;
                }
            }

            span {
                text-transform: lowercase;
            }
        }
    }

    .nav-item-cart {
        padding-bottom: 0.2rem;

        #cart {
            fill: $gold-color;
        }

        #cart-empty {
            fill: #cfcfc5;
        }

        .cart-ico {
            display: block;

            @media only screen and (max-width: $desktop)  {
                width: 2.5rem;
            }

            @media only screen and (min-width: $desktop)  {
                width: 1.5rem;
            }
        }
    }
}

// .nav-link__sublist {
//     position: absolute;
//     top: 1.5rem;
//     left: -50%;
//     background: $black;
//     list-style: none;
//     padding: 1rem;
//     height: 0;
//     opacity: 0;
//     transition: all 0.5s;

//     &-parent {
//         position: relative;

//         &:hover .nav-link__sublist{
//             height: fit-content;
//             opacity: 1;
//         }
//     }

//     li {
//         white-space: nowrap;
//         margin-bottom: 0.5rem;
//     }
// }

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked){
  position: absolute;
  left: -9999px;
  opacity: 0;
  pointer-events: none;
}

.dropdown:checked + label,
.dropdown:not(:checked) + label{
  position: relative;
  transition: all 200ms linear;
  cursor: pointer;
  color: $gold-color;
}

.dropdown:checked + label:before,
.dropdown:not(:checked) + label:before{
  position: fixed;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: auto;
  pointer-events: none;
}
.dropdown:checked + label:before{
  pointer-events: auto;
}

.section-dropdown {
  position: absolute;
  padding: 5px;
  background: rgb(0, 0, 0, 0.8);
  top: 3.5rem;
  left: 0;
  width: 100%;
  border-radius: 4px;
  display: block;
  box-shadow: 0 14px 35px 0 rgba(9,9,12,0.4);
  z-index: 101;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: all 200ms linear;

  @media only screen and (max-width: $desktop)  {
    top: 1rem;
  }
}

.dropdown:checked ~ .section-dropdown{
  opacity: 1;
  width: fit-content;
  pointer-events: auto;
  transform: translateY(0);
  @media only screen and (max-width: $desktop)  {
    background: $dark-background;
    position: relative;
  }
}
.section-dropdown:before {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  content: '';
  display: block;
  z-index: 1;

  @media only screen and (max-width: $desktop)  {
    top: 0px;
  }
}
.section-dropdown:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; 
  border-bottom: 8px solid #111;
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}

.section-dropdown a {
  position: relative;
  transition: all 200ms linear;
  border-radius: 2px;
  padding: 5px 0;
  padding-left: 20px;
  padding-right: 15px;
  margin: 2px 0;
  text-align: left;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  justify-content: space-between;
    -ms-flex-pack: distribute;
}
.dark-light:checked ~ .sec-center .section-dropdown a {
  color: #102770;
}
.section-dropdown a:hover {
  color: #102770;
  background-color: #ffeba7;
}
.dark-light:checked ~ .sec-center .section-dropdown a:hover {
  color: #ffeba7;
  background-color: #102770;
}
