
.q-conatainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: rgb(185,161,113);
    background: radial-gradient(circle, rgba(185,161,113,1) 0%, rgba(101,83,10,1) 0%, rgba(8,6,0,1) 40%);

    .q-title {
        display: flex;
        justify-content: center;
        padding: 2rem 0;
        text-align: center;
        text-transform: uppercase;

        h1 {
            color: #fff;
            width: fit-content;
            border-bottom: 1px solid #fff;
        }
    }

    .accordion {
        padding-top: 5rem;

        .accordion-item {
            background: transparent;

            &:last-child {
                padding-bottom: 3rem;
            }

            .accordion-collapse {
                background: transparent;
            }

            .accordion-body {
                background: #fff;

                @media only screen and (max-width: $desktop)  {
                    font-size: 0.8rem;
                }
            }

            .accordion-body , p {
                @media only screen and (max-width: $desktop)  {
                    font-size: 0.8rem;
                }
            }

            .accordion-button {
                text-transform: uppercase;
                background: #fff;
                color: #000;
                border: 1px solid #000;

                @media only screen and (max-width: $desktop)  {
                    font-size: 0.8rem;
                }

                &::after {
                    background: unset;
                }
            }

            .accordion-button:focus {
                outline: none !important;
            }

            .accordion-button:not(.collapsed)::after {
                background: unset;
            }

            .q-question-ico {
                margin: 0 1rem;

                svg {
                    width: 3rem;
                }
            }
        }

        .accordion-body {
            display: flex;
            margin-left: 6rem;
            color: $gold-color;
            text-transform: uppercase;
            border: 1px solid $gold-color;

            .q-answer-ico {
                margin: 0 0.5rem;

                svg {
                    width: 2.5rem;
                }
            }
        }
    }

    .accordion-collapse {
        transition: all 1s;
    }
}