.product-list {
    background: $light-backgorund;
    padding-bottom: 5rem;
}

.products {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.product-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #fff;
    margin-bottom: 3rem;

    @media only screen  and (max-width: $desktop) {
        flex-wrap: wrap;
    }

    a:hover {
        color: $gold-color;
        border-bottom: 1px solid $gold-color;
    }

    &__item {
        margin: 0.5rem 1rem;
        color: #000;
        font-size: 1rem;
        text-transform: uppercase;
        text-decoration: none;
        transition: color 0.5s, border-color 0.5s;
        border-bottom: 1px solid transparent;

        &.item-active {
            color: $gold-color;
            border-bottom: 1px solid $gold-color;
        }
    }
}

.product__container {
    position: relative;
    background: #fff;
    border: 1px solid $gold-color;
    transition: box-shadow 0.3s;

    @media only screen and (max-width: 768px) {
        grid-column: span 12;

        p {
            font-size: 1rem;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        grid-column: span 6;

        p {
            font-size: 1rem;
        }
    }

    @media only screen and (min-width: 1024px) {
        grid-column: span 4;

        p {
            font-size: 1rem;
        }
    }

    &:hover img{
        transform: scale(1.1) translateY(-50%);
    }

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    }

    .p-title {
        text-transform: uppercase;
        text-align: center;
    }
}

.image__container {
    width: 100%;
    max-width: 700px;
    height: 0;
    padding-bottom: 100%; /* this will create a 1:1 aspect ratio for the container */
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid $gold-color;
  }
  
  .image__container img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.5s;
  }

  .dropdown-bg {
    background: inherit;
  }

  .dropdown-active {
    background: inherit;
  }