.cart {
    background: $light-backgorund;
    min-height: 90vh;

    a {
        color: $gold-color;
    }

    table tbody tr td form button {
        font-size: 1rem;
    }

    .cart-ordering-controls {
        display: block;
        text-align: center;

        a {
            color: $gold-color;
            transition: color 0.5s;

            &:hover {
                color: adjust-color($gold-color, $lightness: 20%);
            }
        }

        button {
            width: 100%;
            padding: 1rem;
        }
    }
}