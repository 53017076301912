footer {
    background: #000;
    position: relative;

    .f-grid {

        @media only screen and (max-width: $desktop) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 
            "logo ."
            "social links"
            "contact links"
            "subscribe subscribe";
        }

        @media only screen and (min-width: $desktop) {
        }
    }

    .f-logo {
        grid-area: logo;
    }

    .f-social {
        grid-area: social;
    }

    .f-contact {
        grid-area: contact;
    }

    .f-links {
        grid-area: links;
    }

    .f-subscribe {
        grid-area: subscribe;
        grid-column: span 2;
    }

    .footer-items {
      h5 {
        font-family: 'trajan', sans-serif;
        color: #fff;
        border-bottom: 2px solid $gold-color;
        width: fit-content;
      }

      .newsletter-link {
        color: $gold-color;
        transition: color 0.5s;

        &:hover {
            color: adjust-color($gold-color, $lightness: 20%);
        }
      }
    }

    p, a {
        font-family: Verdana, 'trajan', Tahoma, sans-serif;
    }

    ul li a svg {
        fill: $gold-color;
    }

    .text-small {
        font-size: 0.9rem;
    }
    
    a {
        color: inherit;
        text-decoration: none;
        transition: all 0.3s;
    }
    
    a:hover, a:focus {
        text-decoration: none;
        color: $gold-color;
    }
    
    .form-control {
        background: #212529;
        border-color: $gold-color;
        color: #fff;
    }
    
    .form-control:focus {
        background: #212529;
        box-shadow: 0 0 10px $gold-color;
        border: 1px solid $gold-color;
        color: #fff;
    }

    .btn {
        background: $gold-color;
    }

    .footer-logo {

        svg {
            width: 100%;

            @media only screen and (max-width: $desktop) {
                margin: 1rem 0;
                width: 60%;
            }
        }
    }

    .modal {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        justify-content: center;
        align-items: center;
        z-index: 9999;
        }
    
    .modal-content {
        background-color: #fff;
        padding: 20px;
        text-align: center;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        @media only screen and (max-width: $desktop) {
            width: 100%;
        }

        @media only screen and (min-width: $desktop) {
            width: 50%;
        }
    }
}