// If you comment out code below, bootstrap will use red as primary color
// and btn-primary will become red
// $primary: red;
// .jumbotron {
//   // should be relative path of the entry scss file
//   background-image: url("../../vendors/images/sample.jpg");
//   background-size: cover;
// }

// import Swiper styles
@import 'swiper/css';
@import '../../node_modules/swiper/swiper-bundle.css';

@import "~bootstrap/scss/bootstrap.scss";
@import 'variables';
@import "nav";
@import "landing";
@import "product_list";
@import "product_detail";
@import "global";
@import "footer";
@import "recipe_list";
@import "recipe_detail";
@import "fonts";
@import "faq";
@import "truffles";
@import "pagination";
@import "cart";
@import "contact";
@import "unsubscribe";
@import "gifts";
@import "order_success";
