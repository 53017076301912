.recipes-container {
    position: relative;
    height: 80vh;
    max-width: 100%;
}

.r-slide {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    transition: opacity 1s ease-in-out;
    
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__name {
        position: absolute;
        text-align: center;
        left: 50%;
        top: 60%;
        transform: translateX(-50%);

        
        h1 {
            font-size: 3rem;
            color: #fff;

            @media only screen and (max-width: $desktop) {
                font-size: 2rem;
            }
        }
    
        h3 {
            color: #fff;
            font-family: 'merdeka', monospace;
        }
    }
}

.recipes {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-bottom: 5rem;
}

.recipe__container {
    position: relative;
    background: $white;

    @media only screen and (max-width: 768px) {
        grid-column: span 12;

        p {
            font-size: 1.2rem;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        grid-column: span 6;

        p {
            font-size: 1.2rem;
        }
    }

    @media only screen and (min-width: 1024px) {
        grid-column: span 4;

        p {
            font-size: 1.3rem;
        }
    }

    &:hover img{
        transform: scale(1.1) translateY(-50%);
    }

    .image__container {
        border-left: 4px solid white;
        border-top: 4px solid white;
        border-right: 4px solid white;
        border-bottom: none;
    }
}

.dark-bg {
    background: $dark-background;
    padding-top: 5rem;

    .r-title {
        text-transform: uppercase;
        text-align: left;
        padding-left: 1rem;
    }
}